$(window).on("load", function() {

	//
	// js pour afficher la carte
	//
	$('div.maps-cont > a').on('click', function(event) {
		event.preventDefault();
		if(!$('div.maps-cont > iframe').length) {
			$(this).after('<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d21121.612574915664!2d7.513890999999999!3d48.567688!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x85c66acfa23c9753!2sDomaine%20Siebert!5e0!3m2!1sfr!2sfr!4v1654595264535!5m2!1sfr!2sfr" class="pb-3 px-3 w-100" allowfullscreen></iframe>');
		} else {
			$('div.maps-cont > iframe').slideToggle();
		}
        
		$('html, body').stop().animate({scrollTop: $('div.maps-cont > iframe').offset().top}, 1000);
	});

	window.addEventListener('scroll', function(e) {
		let nav_cont = document.getElementById('navigation-cont');
		if(window.scrollY > 0) {
			nav_cont.classList.add('bg-black-trans');
		} else {
			nav_cont.classList.remove('bg-black-trans');
		}
	});

	if(window.scrollY > 0) {
		document.getElementById('navigation-cont').classList.add('bg-black-trans');
	}

	$('.btn-top').on('click', function() {
        $('html,body').animate({scrollTop: 0}, 'linear');
	});
});